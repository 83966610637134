/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("@rails/ujs").start();

// DnrLayout Libraries Below:
var jQuery = require("jquery");
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

// Bootstrap Components
//import "bootstrap/dist/js/bootstrap";
import "bootstrap/js/src/button";
import "bootstrap/js/src/collapse";
import "bootstrap/js/src/dropdown";
import "bootstrap/js/src/popover";
import "bootstrap/js/src/util";

$(document).ready( function () {
    
    const redirectUrl = $(document.body).data('session-expired-url');
    const extranetAdmin = $(document.body).data('extranet-admin');
    if (window.location.href.indexOf('signin') > -1 || !extranetAdmin)
         return; 
    

    const idleDurationSecs = 1800; // 30 minutes 
    let idleTimeout; // variable to hold the timeout, do not modify

    const resetIdleTimeout = function() {

      // Clears the existing timeout
      if(idleTimeout) clearTimeout(idleTimeout);

      // Set a new idle timeout to load the redirectUrl after idleDurationSecs
      idleTimeout = setTimeout(() => location.href = redirectUrl, idleDurationSecs * 1000);
    };

    // Init on page load
    resetIdleTimeout();

    // Reset the idle timeout on any of the events listed below
    ['click'].forEach(evt =>
      document.addEventListener(evt, resetIdleTimeout, false)
    );

});
